<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Đối soát giao dịch sử dụng coupon</h6>
        </template>
        <div class="filter">
          <b-row>
            <b-col>
              <b-form-group label="Ngày bắt đầu(*)">
                <b-form-input
                    :id="`type-date`"
                    :type="`date`"
                    v-model="filter.date_from"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Ngày kết thúc(*)">
                <b-form-input
                    :id="`type-date`"
                    :type="`date`"
                    v-model="filter.date_to"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Campaign">
                <b-form-input v-model="filter.campaign"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Trạng thái">
                <b-form-select :options="transactionStatus" v-model="filter.status"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Coupon code">
                <b-form-input v-model="filter.coupon_code"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Code">
                <b-form-input v-model="filter.code"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Trans_id">
                <b-form-input v-model="filter.trans_id"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="text-right">
                <b-button-group>
                  <b-button type="button" variant="primary" @click="search()"><i class="fa fa-search"></i> Tìm kiếm</b-button>
                  <b-button type="button" variant="success" @click="showModal()"><i class="fa fa-download"></i> Xuất Excel</b-button>
                </b-button-group>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="content">
          <b-row>
            <b-col>
              <div><span>Tổng giao dịch: </span><strong>{{ numberFormat(paginate.total) }}</strong></div>
            </b-col>
          </b-row>

          <b-table :items="items" :fields = "fields" ref="table" striped hover responsive caption-top>
            <template #cell(index)="data">
              {{(currentPage - 1) * 25 + (data.index + 1)}}
            </template>
            <template #cell(created_at)="data">
              {{ formatDate(data.item.created_at) }}
            </template>
            <template #cell(updated_at)="data">
              {{ formatDate(data.item.updated_at) }}
            </template>
            <template #cell(amount)="data">
              {{ numberFormat(data.item.amount) }}
            </template>
            <template #cell(price)="data">
              {{ data.item.price ? numberFormat(data.item.price) : '' }}
            </template>
            <template #cell(voucher)="data">
              {{ numberFormat(data.item.voucher) }}
            </template>
            <template #cell(coupon_real_discount)="data">
              {{ numberFormat(data.item.coupon_real_discount) }}
            </template>
            <template #cell(service)="data">
              {{ handleService(data.item.service) }}
            </template>
          </b-table>

          <div class="mt-3">
            <b-pagination-nav
                v-model="currentPage"
                :link-gen="createLinkPage"
                :number-of-pages="paginate.totalPage"
                use-router
                first-number
                last-number
                align="right"
            ></b-pagination-nav>
          </div>
        </div>
      </b-card>
    </b-card-group>

    <export-modal :modalId="exportId" @exportData="exportData"></export-modal>
    <alert-dialogue ref="confirmDialogue"></alert-dialogue>
  </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import ExportModal from "@/view/pages/components/ExportModal.vue";
import AlertDialogue from "@/view/pages/components/AlertModal.vue";
import router from "@/router";

Vue.use(Notifications);

const TransactionRepository = RepositoryFactory.get("transactions");
export default {
  components: { AlertDialogue, ExportModal },
  mixins: [Common],
  data() {
    return {
      exportId: "export_trans_with_coupon_reconcile",
      items: null,
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      filter: {
        date_from: this.getFirstDayOfMonth(),
        date_to: this.getCurrentDay(),
        status: null,
        campaign: null,
        coupon_code: null,
        trans_id: null,
        code: null
      },
      transactionStatus: [
        { value: "", text: "Tất cả" },
        { value: "PENDING", text: "PENDING" },
        { value: "SUCCESS", text: "SUCCESS" },
        { value: "CREATE", text: "CREATE" },
        { value: "CREATE_ERROR", text: "CREATE_ERROR" },
        { value: "CREATE_REPLACE", text: "CREATE_REPLACE" },
        { value: "CREATE_SUCCESS", text: "CREATE_SUCCESS" },
        { value: "CREATED", text: "CREATED" },
        { value: "FAIL", text: "FAIL" },
        { value: "CANCEL", text: "CANCEL" },
        { value: "PROCESSING", text: "PROCESSING" },
        { value: "REFUND", text: "REFUND" },
        { value: "REVERSED", text: "REVERSED" },
      ],
      fields: [
        {
          key: "index",
          label: "STT",
        },
        {
          key: "sub_description",
          label: "Campaign",
        },
        {
          key: "name"
        },
        {
          key: "code"
        },
        {
          key: "voucher",
        },
        {
          key: "created_at",
          label: "Ngày tạo"
        },
        {
          key: "updated_at",
          label: "Ngày cập nhật"
        },
        {
          key: "wallet_transaction_id",
          label: "Trans_id"
        },
        {
          key: "user_phone",
          label: "Số điện thoại"
        },
        {
          key: "coupon_code",
          label: "Coupon code"
        },
        {
          key: "description",
          label: "Mô tả"
        },
        {
          key: "action",
          label: "DV sử dụng",
        },
        {
          key: "service",
          label: "DV voucher",
        },
        {
          key: "status",
          label: "Trạng thái",
        },
        {
          key: "voucher",
          label: "Voucher"
        },
        {
          key: "price",
          label: "Price"
        },
        {
          key: "amount",
          label: "Amount"
        },
        {
          key: "coupon_real_discount",
          label: "Coupon"
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Đối soát voucher", route: "with-voucher" },
    ]);
  },
  methods: {
    search() {
      this.filter.page = 1;
      router.push({ path: "/transactions/with-voucher", query: this.filter }).catch(() => {});

      if (this.currentPage !== 1) {
        this.currentPage = 1;
        return;
      }

      this.getTransactions();
    },
    async getTransactions()
    {
      this.$bus.$emit("show-loading", true);
      this.items = null;
      let params = this.convert(this.filter);
      params.page = this.currentPage;
      let response = await TransactionRepository.transactionWithVoucher(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;
      if (body.error_code) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }

      this.items = body.data.data;
      this.paginate.totalPage = body.data.last_page;
      this.paginate.total = body.data.total;
      this.paginate.page = body.data.current_page;
      this.paginate.limit = body.data.per_page;
    },
    showModal() {
      this.$bvModal.show(this.exportId);
    },
    async exportData(description = null) {
      this.$bus.$emit("show-loading", true);
      let params = this.convert(this.filter);
      params.export_desc = description
      this.$bvModal.hide(this.exportId);
      let response = await TransactionRepository.exportTransactionWithVoucher(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;
      if (body.error_code) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }

      this.$bus.$emit("show-loading", false);
      let link = window.location.origin + "/#/reconcile/export-data";
      let cfm = await this.$refs.confirmDialogue.show({
        title: "Download File",
        message: "File đang được xử lý, bạn có thể tải xuống sau ít phút <a href='" + link + "' target='_blank'>TẠI ĐÂY</a>",
        okButton: "Ok",
      });

      if (!cfm) {
        self.$bus.$emit("show-loading", false);
        return (self.errorMessage = "Có lỗi khi lấy thông tin");
      }
    },
    convert(params) {
      Object.keys(params).forEach(key => {
        if (params[key] === null || params[key] == '') {
          delete params[key];
        }
      });
      return params;
    },
    handleService(services) {
      let stringService = services.replace("CARD_PHONE", "PHONE_CARD");
      let stringService2 = stringService.replace("[", "");
      let stringService3 = stringService2.replace("]", "");
      let stringService4 = stringService3.replace("TOPUP_MG", "TOPUP_GAME");
      let stringService5 = stringService4.replace("CARD_MG", "GAME_CARD");
      return stringService5.replace("TOPUP_MOBILE", "TOPUP_PHONE");
    }
  },
  created() {
    this.getTransactions();
  },
  watch: {
    currentPage(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getTransactions();
      }
    },
  },
};
</script>
